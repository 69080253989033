* {
  margin: 0%;
  padding: 0%;
  box-sizing: border-box;
}
.comming-soon {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  gap: 1rem;
}
.company-name {
  font-size: 50px;
}
@media screen and (max-width: 475px) {
  .company-name {
    font-size: 40px;
  }
}
@media screen and (max-width: 320px) {
  .company-name {
    font-size: xx-large;
  }
  .comming-soon {
    font-size: larger;
  }
}
